section {
    display: flex;
    flex-direction: column;

    .divisor {
        flex-direction: column;
    }
}

.icon {
    width: 120px;
}

.company-title { 
    font-size: 46px;
    margin-top: 20px;
    margin-bottom: 0;
}

.description {
    margin-top: 0;
    margin-bottom: 25px;
}

@media (min-width: 768px) and (max-width: 2440px) {
    .icon {
        width: 120px;
    }

    section {
        padding: 40px;
        flex-direction: column;

        .divisor {
            display: flex;

            flex-direction: row;
        }
    }

    .description-section {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        img {
            width: 400px;
        }

        .description-section-infos {

        }
    }
}