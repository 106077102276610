body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

footer {
  padding: 24px;
}

section {
  padding: 24px;
}

.bg-purple {
  background-color: #322c4d;
  color: white;
}

.bg-gray {
  background-color: #fafafa;
  color: #322c4d;
}

.bg-black {
  background-color: #000000;
  color: #ffffff;
}

img {
  animation: dash 5s ease-in forwards;
}

@media screen and (max-width: 1023px) {
  img {
    width: -webkit-fill-available;
  }
}
