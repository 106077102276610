button {
    background-color: #000000;
    border: 1px solid #ffffff;
    padding: 8px 22px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 8px;
    color: #ffffff;
}

.variant-2 {
    background-color: #E3E6E0;
    border: 1px solid #322C4D;
    color: #322C4D;
}